.subscription {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 70px;
	max-width: 768px;
}

.title {
	font-family: e-Ukraine-Head;
	font-size: 48px;
	line-height: 67px;
	text-align: center;
}

.text {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	gap: 7px;

	font-family: e-Ukraine-Head;
	font-size: 18px;
	line-height: 25px;

	&Icon {
		position: relative;

		height: 24px;
		width: 24px;
		flex-shrink: 1;

		background-color: #426cff;
		border-radius: 50%;

		&::before {
			content: '';
			position: absolute;
			top: 5px;
			left: 9px;
			width: 6px;
			height: 12px;

			border: 2px solid #fff;
			border-top: 0;
			border-left: 0;

			transform: rotate(40deg);
		}
	}
}

.form {
	display: flex;
	justify-content: center;
	max-width: 580px;
	padding: 20px;

	background-color: #191818;
	border: 1px solid #323232;
	border-radius: 100px;

	&:hover,
	&:focus,
	&:active {
		border-color: #7f8392;
	}
}

.mail {
	display: flex;
	width: 100%;
	margin: 0 10px;

	font-family: e-Ukraine-Head;
	font-size: 18px;
	line-height: 25px;
	color: white;

	background: transparent;
	outline: none;
	caret-color: white;

	&::placeholder {
		color: #7f8392;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-text-fill-color: white !important;
		-webkit-box-shadow: 0 0 0 50px #191818 inset !important;
		box-shadow: 0 0 0 50px #191818 inset !important;
		color: white !important;
	}
}

@media screen and (max-width: 768px) {
	.title {
		font-size: 30px;
		line-height: 41px;
	}

	.form {
		gap: 20px;
		max-width: 480px;
		width: 100%;

		flex-direction: column;
		align-items: center;
		padding: 0;

		background-color: transparent;
		border: none;
		border-radius: none;
	}

	.mail {
		display: flex;
		padding: 25px 30px;
		margin: 0;

		background-color: #191818;
		border: 1px solid #323232;
		border-radius: 100px;

		&:hover,
		&:focus,
		&:active {
			border-color: #7f8392;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-text-fill-color: white !important;
			-webkit-box-shadow: 0 0 0 150px #191818 inset !important;
			box-shadow: 0 0 0 150px #191818 inset !important;
			color: white !important;
		}
	}
}

@media screen and (max-width: 480px) {
	.subscription {
		gap: 50px;
	}

	.title {
		font-size: 25px;
		line-height: 34px;
	}

	.mail {
		font-size: 15px;
		line-height: 18px;
	}
}
