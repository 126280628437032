.root {
	padding: 120px 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #020202;
}

.container {
	width: 100%;
	max-width: 1440px;
	padding: 0 80px;
	margin: 0 auto;
}

.title {
	margin-bottom: 70px;

	font-family: 'e-Ukraine-Head', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 140%;

	color: #ffffff;
}

.list {
	display: flex;
	flex-wrap: wrap;

	gap: 50px;
}

.listSlider {
	display: none;
}

.item {
	text-decoration: none !important;

	padding: 10px 20px;

	background: #191818;
	border: 1px solid #323232;
	border-radius: 100px;

	width: 380px;
	height: 109px;

	display: grid;
	grid-template-columns: 90px 1fr;
	grid-column-gap: 17px;

	align-items: center;

	&:hover {
		text-decoration: none;
		border-color: #7f8392;
	}
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}

.itemLogo {
	width: 100%;
	border-radius: 50%;

	aspect-ratio: 1 / 1;
}

.itemTitle {
	font-family: 'e-Ukraine', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 140%;

	color: #ffffff;
	&:hover {
		text-decoration: none;
	}
}

.itemSubtitle {
	font-family: 'e-Ukraine', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 281%;

	color: #7f8392;
}

@media screen and (max-width: 768px) {
	.root {
		min-height: auto;
		padding-bottom: 0;
	}

	.container {
		padding-right: 0;
		padding-left: 30px;
	}

	.list {
		display: none;
	}

	.listSlider {
		display: block;
	}

	.title {
		font-size: 30px;
		line-height: 136%;
		margin-bottom: 50px;
	}

	.item {
		grid-column-gap: 10px;
		width: 290px;
		padding: 10px;
	}

	.itemTitle {
		font-size: 15px;
		line-height: 140%;
	}

	.itemSubtitle {
		font-size: 13px;
		line-height: 22px;
	}
}

@media screen and (max-width: 480px) {
	.container {
		padding-right: 0;
		padding-left: 20px;
	}
}
