.section-credibility {
	.section-title {
		margin-bottom: 20px;
		border: none;
	}

	@media screen and (min-width: 768px) {
		.section-title {
			margin-bottom: 40px;
		}
	}
}

.credibility-list {
	display: none;

	@media screen and (min-width: 768px) {
		display: flex;
		flex-wrap: wrap;

		gap: 50px;
	}
}
.credibility-slider {
	@media screen and (min-width: 768px) {
		display: none;
	}
}

.credibility-item {
	padding: 20px 0;
	width: 340px;
	display: grid;
	grid-template-columns: 70px 1fr;
	grid-column-gap: 15px;
	border-top: 1px solid #6b6b6b;

	&:hover {
		.credibility-subtitle img {
			transform: rotate(180deg);
			transition: 0.5s;
		}
	}

	@media screen and (max-width: 480px) {
		width: 283px;
	}
}

.credibility-logo {
	width: 70px;
	border-radius: 50%;
	aspect-ratio: 1 / 1;
}

.credibility-content {
	text-transform: uppercase;
}

.credibility-title {
	font-family: e-Ukraine-Head-Medium;
	font-size: 16px;
	line-height: 32px;
}

.credibility-subtitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #b5b5b5;
	font-family: e-Ukraine-Thin;
	font-size: 16px;
	line-height: 32px;

	img {
		height: 20px;
		transform: rotate(135deg);
		transition: 0.5s;
	}
}
