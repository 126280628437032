.root {
	padding: 120px 0;

	background: #020202;

	min-height: 100vh;

	display: flex;
	align-items: center;
}

.container {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 50px;
}

.info {
	display: flex;
	align-items: center;

	justify-content: space-between;

	margin-bottom: 70px;
	padding: 0 30px;

	button:hover {
		border-color: #7f8392;
	}
}

.title {
	font-family: 'e-Ukraine-Head', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 140%;

	color: #ffffff;
}

.slider {
	width: 100%;
}

.slide {
	max-width: 394px;
	height: 530px;
	width: 100%;

	height: 530px !important;
}

.slideContainer {
	position: relative;

	padding: 10px;
	height: 100%;

	background: #191818;
	border: 1px solid #323232;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.slideShadow {
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	border-radius: 18px;
	background: linear-gradient(
		180.53deg,
		#080808 10.16%,
		rgba(8, 8, 8, 0) 99.54%
	);
	height: 200px;
}

.slideImage {
	max-height: 337px;
	width: 100%;

	overflow: hidden;

	border-radius: 20px;
}

.slideInfo {
	position: absolute;
	top: 35px;
	left: 20px;
}

.slideTitle {
	margin-bottom: 6px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 100%;

	color: #ffffff;
}

.slideAuthor {
	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 35px;

	color: rgba(255, 255, 255, 0.6);
}

.slideDesc {
	padding: 20px 10px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;

	color: #b5b5b5;
}

@media screen and (max-width: 768px) {
	.container {
		padding: 0;
	}

	.title {
		font-size: 30px;
		line-height: 136%;
	}

	.slide {
		max-width: 335px;
	}

	.slideTitle {
		font-size: 22px;
		line-height: 23px;
	}

	.slideAuthor,
	.slideDesc {
		font-size: 13px;
		line-height: 22px;
	}
}

@media screen and (max-width: 480px) {
	.root {
		padding-top: 40px;
	}

	.container {
		padding: 0;

		h2 {
			padding: 0 20px;
		}
	}

	.info {
		padding: 0 20px;
		margin-bottom: 50px;
		flex-direction: column;
		gap: 25px;
	}

	.slide {
		padding: 0 20px;
		max-width: 100%;
	}
}
