.section-about {
	p {
		img {
			display: inline;
		}
	}

	.section-title {
		@media screen and (min-width: 768px) {
			align-items: center;
		}
	}
}

.about-info {
	display: flex;
	flex-direction: column-reverse;

	@media screen and (min-width: 768px) {
		margin-top: 200px;
		margin-bottom: 200px;
		display: grid;
		grid-template-columns: 0.8fr 1.2fr;
		grid-column-gap: 120px;
		align-items: center;
	}
}

.about-image {
	padding: 30px 20px 60px;
	display: flex;
	justify-content: center;

	@media screen and (min-width: 768px) {
		padding: 0;
	}
}

.about-title {
	margin: 30px 0;
	font-family: e-Ukraine-Head-Medium;
	font-size: 24px;
	line-height: 40px;
	text-transform: uppercase;

	@media screen and (min-width: 768px) {
		font-size: 35px;
		line-height: 45px;
	}
}

.about-video {
	video {
		margin: 40px 0;
	}

	@media screen and (min-width: 768px) {
		margin-top: 120px;
		gap: 100px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;

		p,
		video {
			margin: 0;
		}
	}
}

.about-card {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.about-photo {
	margin: 20px 0;
	height: 280px;
	width: 280px;
	display: flex;
	align-items: flex-end;
	background-color: #131313;

	img {
		object-fit: contain;
	}

	@media screen and (min-width: 768px) {
		height: 340px;
		width: 340px;
	}
}

.about-name {
	h4 {
		font-family: e-Ukraine-Head;
		font-size: 16px;
		line-height: 22px;
	}

	span {
		color: #7f8392;
		font-family: e-Ukraine-Head-Medium;
		font-size: 12px;
		line-height: 14px;
	}

	@media screen and (min-width: 768px) {
		h4 {
			font-size: 20px;
			line-height: 28px;
		}

		span {
			font-size: 14px;
			line-height: 17px;
		}
	}
}

.about-slider {
	padding: 120px 0 100px !important;
	margin-top: -100px;

	@media screen and (max-width: 768px) {
		padding: 120px 0 50px !important;
	}

	.swiper-pagination {
		top: auto !important;
		bottom: 0 !important;
		height: 2px !important;

		.swiper-pagination-progressbar-fill {
			background: #ffffff;
		}
	}
}
