.root {
	padding: 120px 0;
	min-height: calc(100vh - 90px);

	display: flex;
	align-items: center;

	background: #020202;
	background-image: url('./assets/info-bg.png');

	background-size: 100% 110%;
}

.container {
	max-width: 960px;
	width: 100%;
	margin: 0 auto;
}

.title {
	margin-bottom: 60px;

	font-family: 'e-Ukraine-Head', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 64px;
	line-height: 120%;

	text-align: center;

	color: #ffffff;
}

.description {
	margin-bottom: 80px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 150%;

	text-align: center;
	color: #b5b5b5;

	a {
		color: #426cff;
	}
}

.list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	gap: 32px;
}

.item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	position: relative;

	align-items: center;

	// background: rgba($color: #191818, $alpha: 0.3);
	border-radius: 42px;

	padding: 50px 20px 60px;

	&:before {
		content: ' ';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		opacity: 0.09;
		background-image: url('./assets/banner-value-bg.png');
		background-repeat: no-repeat;
		background-position: 0;
		background-size: cover;
		border-radius: 42px;
	}
}

.link {
	color: #426cff;
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}

.whiteText {
	color: #fff;
}

.itemTitle {
	font-family: 'e-Ukraine-Head', sans-serif;
	font-size: 70px;
	line-height: 84px;

	color: #426cff;
}

.itemText {
	display: flex;
	align-items: center;
	height: 100%;

	font-family: 'e-Ukraine-Head', sans-serif;
	font-size: 25px;
	line-height: 36px;
	text-align: center;

	color: #b5b5b5;
}

@media screen and (max-width: 1280px) {
	.root {
		padding: 60px 0 80px;
		min-height: calc(100vh - 82px);
	}

	.container {
		max-width: 960px;

		padding: 0 40px;
	}

	.title {
		margin-bottom: 25px;
		font-size: 37px;
	}

	.description {
		margin-bottom: 50px;

		font-size: 16px;
	}

	.list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		gap: 20px;
	}

	.item {
		border-radius: 42px;

		padding: 25px 50px 30px;
	}

	.itemTitle {
		font-size: 40px;
		line-height: 48px;
	}

	.itemText {
		font-size: 18px;
		line-height: 27px;
	}
}

@media screen and (max-width: 768px) {
	.root {
		padding: 40px 0 80px;
		min-height: calc(100vh - 82px);
	}

	.container {
		padding: 0 30px;
	}

	.list {
		display: flex;
		flex-direction: column;

		align-items: center;
	}

	.item {
		max-width: 300px;
		width: 100%;
	}
}

@media screen and (max-width: 480px) {
	.container {
		padding: 0 20px;
	}
}
