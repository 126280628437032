.header {
	z-index: 1000;
	position: sticky;
	top: 0px;
	left: 0;

	padding: 20px 20px 20px 12px;

	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;

	background: var(--color--black);
	transition: all 0.35s;

	.buy-arrow {
		padding: 5px 12px;
		cursor: pointer;

		display: flex;
		align-items: center;
		svg {
			position: relative;
			margin-left: 10px;
			width: 25px;
			height: 25px;
			stroke: var(--color--white);
			transition: all 0.35s;
		}

		span {
			font-size: 20px;
			line-height: 42px;
			color: rgba(255, 255, 255, 0.9);
			font-family: 'StolzlBold';
			transition: all 0.35s;
		}

		&:hover {
			svg {
				stroke: var(--color--pink-light);
				transform: rotate(45deg);
				margin-left: 11px;
			}

			span {
				-webkit-text-stroke: 1px var(--color--pink-light);
				text-shadow: 0px 0px 4px var(--color--pink-light);
				color: var(--color--black);
			}
		}

		@media screen and (min-width: 481px) {
			svg {
				width: 42px;
				height: 42px;
			}
		}
	}

	@media screen and (max-width: 480px) {
		display: grid;
		grid-template-areas: 'logo burger' 'link link';

		.logo-icon {
			grid-area: logo;
		}

		.burger-icon {
			justify-self: end;
			grid-area: burger;
		}

		.buy-arrow {
			grid-area: link;
		}
	}
}
