.burger-icon {
	// position: fixed;
	width: 25px;
	height: 25px;
	z-index: 1000;
	// right: 40px;
	// top: 26px;

	@media screen and (min-width: 481px) {
		width: 40px;
		height: 40px;
	}

	img {
		width: 100%;
	}
	&:hover {
		cursor: pointer;
	}
}
