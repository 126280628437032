.logo-icon {
	cursor: pointer;

	z-index: 1000;
	// position: fixed;
	// left: 40px;
	// top: 21px;
	width: 40px;
	height: 40px;
	user-select: none;
}
