.info-arrow {
	position: relative;
	width: 335px;
	height: 335px;
	background: var(--color--dark);
	border: 1px solid var(--color--white);
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;
	user-select: none;

	.outer {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		z-index: 1;

		img {
			width: 179px;
			height: 158px;
		}
	}

	.inner {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		height: 0;
		width: 0;
		border-radius: 50%;
		background: var(--color--dark);
		border: 1px solid var(--color--white);
		transition: all 0.6s;
		opacity: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			width: 100%;
			height: 100%;
			opacity: 0;
			object-fit: cover;
			transition: all 0.5s;
		}
	}

	&:hover .inner {
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 1;

		img {
			opacity: 1;
		}
	}

	&:hover .inner img {
		transition-delay: 0.3s;
	}
}
