.connect-footer {
	@media screen and (min-width: 481px) {
		display: flex;
		flex-direction: column;
		height: 100vh;
	}

	@media screen and (min-width: 1024px) {
		display: flex;
		flex-direction: column;
		height: 100vh;
	}

	.connect {
		margin: 0 20px;

		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		.text-heading {
			flex: 1;
			display: flex;
			flex-direction: column;
			letter-spacing: -0.4px;

			@media screen and (min-width: 481px) {
				align-items: center;
				flex-direction: row;
				align-items: center;

				padding: 0 100px;
			}

			.text-heading__number {
				font-size: 30px;
				margin-right: 63px;
				font-size: 15px;
				line-height: 42px;

				@media screen and (min-width: 481px) {
					font-size: 30px;
				}

				@media screen and (min-width: 1024px) {
					font-size: 30px;
				}
			}

			div {
				display: flex;
				flex-direction: column;

				font-size: 35px;
				line-height: 40px;
				text-transform: uppercase;

				span {
					z-index: 1;
					font-family: 'StolzlBold';
					margin-bottom: 6px;

					@media screen and (min-width: 481px) {
						font-size: 90px;
						line-height: 95px;
					}

					@media screen and (min-width: 1024px) {
						font-size: 90px;
						line-height: 95px;
					}
				}
			}
		}

		&-content {
			padding: 60px 0;

			flex: 1;

			display: flex;
			flex-direction: column;

			border-top: 1px solid rgba(255, 255, 255, 0.45);
			border-bottom: 1px solid rgba(255, 255, 255, 0.45);

			@media screen and (min-width: 481px) {
				padding: 0;
				margin: 0 100px 0 205px;

				flex-direction: row;

				justify-content: space-between;
				align-items: center;
			}

			@media screen and (min-width: 1024px) {
				padding: 0;

				margin: 0 100px 0 205px;

				flex-direction: row;

				justify-content: space-between;
				align-items: center;
			}
			a {
				display: block;
				margin-bottom: 30px;
				font-family: 'StolzlMedium';
				font-size: 25px;
				line-height: 42px;

				color: var(--color--white);
				opacity: 0.95;
				text-decoration: none;

				@media screen and (min-width: 481px) {
					margin-bottom: 0;

					font-size: 50px;
					line-height: 42px;
				}

				@media screen and (min-width: 1024px) {
					margin-bottom: 0;

					font-size: 50px;
					line-height: 42px;
				}

				&:hover {
					span {
						position: relative;
						cursor: pointer;
						-webkit-text-stroke: 1px var(--color--pink-light);
						text-shadow: 0px 0px 4px var(--color--pink-light);
						color: var(--color--black);
					}

					svg {
						stroke: var(--color--pink-light);
						transform: rotate(45deg);
					}
				}

				div {
					display: flex;
					align-items: center;

					span {
						transition: all 0.35s;
					}

					svg {
						position: relative;
						top: 4px;

						margin-left: 5px;

						width: 25px;
						height: 25px;

						transition: all 0.35s;
						stroke: var(--color--white);

						@media screen and (min-width: 481px) {
							width: 42px;
							height: 42px;
						}

						@media screen and (min-width: 1024px) {
							width: 42px;
							height: 42px;
						}
					}
				}
			}
		}
	}

	.line {
		margin-bottom: 0;
	}

	.footer {
		margin: 64px 20px 12px;

		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@media screen and (min-width: 481px) {
			margin: 188px 105px 77px 222px;
		}

		&__rights,
		&__title {
			font-size: 14px;
			line-height: 30px;
		}

		@media screen and (min-width: 481px) {
			font-size: 16px;
		}

		&__title {
			@media screen and (max-width: 480px) {
				display: none;
			}
		}
	}
}
