.purchase {
	&Container {
		display: flex;
		align-items: center;
		position: relative;
		flex-direction: column;
		margin-top: 5rem;
	}

	&Price {
		display: flex;
		justify-content: center;
		gap: 10px;

		&.__center {
			align-items: center;
		}

		& > span {
			font-family: 'e-Ukraine', sans-serif;
			font-size: 20px;
			line-height: 150%;

			@media screen and (max-width: 768px) {
				font-size: 16px;
				line-height: 150%;
			}
		}

		& > strong {
			font-family: 'e-Ukraine', sans-serif;
			font-size: 20px;
			line-height: 150%;

			@media screen and (max-width: 768px) {
				font-size: 16px;
				line-height: 150%;
			}

			color: #426cff;
		}
	}

	&Transaction {
		font-family: 'e-Ukraine';
		font-size: 20px;
		line-height: 30px;
		margin-top: 0.8rem;
		text-align: center;

		& b {
			color: #426cff;
			font-family: 'e-Ukraine', sans-serif;
		}

		@media screen and (max-width: 768px) {
			font-size: 16px;
			line-height: 150%;
		}
	}
}

@media screen and (max-width: 480px) {
	.purchase {
		&Container {
			flex-direction: column;
			padding-bottom: 0;
			margin-top: 3rem;
		}
		&Price {
			margin-top: 10px;
			flex-direction: row;
			& > span {
				font-size: 16px;
			}
			& > strong {
				font-size: 20px;
			}
		}

		&Transaction {
			//background-color: white;
			text-align: center;
			font-size: 14px;
			&.__center {
				margin-bottom: 16px;
			}
		}
	}
}
