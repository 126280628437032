@font-face {
	font-family: 'e-Ukraine-Head';
	src: url('./fonts/e-ukraine-head/e-UkraineHead-Bold.otf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'e-Ukraine-Head-Regular';
	src: url('./fonts/e-ukraine-head/e-UkraineHead-Regular.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'e-Ukraine-Head-Medium';
	src: url('./fonts/e-ukraine-head/e-UkraineHead-Medium.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'e-Ukraine';
	src: url('./fonts/e-ukraine/e-Ukraine-Regular.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'e-Ukraine-Light';
	src: url('./fonts/e-ukraine/e-Ukraine-Light.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'e-Ukraine-Thin';
	src: url('./fonts/e-ukraine/e-Ukraine-Thin.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'StolzlRegular';
	src: url('./fonts/Stolzl/Stolzl-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'StolzlBold';
	src: url('./fonts/Stolzl/Stolzl-Bold.ttf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'StolzlMedium';
	src: url('./fonts/Stolzl/Stolzl-Medium.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'StolzlBook';
	src: url('./fonts/Stolzl/Stolzl-Book.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'StolzlThin';
	src: url('./fonts/Stolzl/Stolzl-Thin.ttf');
	font-weight: normal;
	font-style: normal;
}
