.container {
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	//background-color: transparent;
	background: url('nfts.jpg') no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	//height: 100vh;
	padding: 122px 0 144px;
	object-fit: contain;
	position: relative;

	&Header {
		margin-inline: auto;
		font-size: 60px;
		margin-top: 4rem;
		margin-bottom: 4rem;
		font-family: 'e-Ukraine', sans-serif;
		z-index: 1;
	}

	&Footer {
		z-index: 1;
		margin-top: 3rem;
		padding: 0 calc(max(20vw, 60px)) 10px;
		text-align: center;
		font-family: 'e-Ukraine-Light', sans-serif;
		font-size: 15px;
		line-height: 29px;
		color: #b5b5b5;
	}
}

.shadowTop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	transform: rotate(180deg);
}

.shadowBottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

@media screen and (max-width: 480px) {
	.container {
		&Footer {
			margin-top: 2rem;
			padding: 0 25px 10px 25px;

			font-size: 13px;
			line-height: 22px;
		}

		&Header {
			font-size: 28px;
		}
	}
}
