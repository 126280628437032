.btnFixed {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	gap: 10px;
	height: 40px;
	width: 100%;
	padding: 8px;

	font-family: e-Ukraine-Head;
	font-size: 16px;
	line-height: 19px;
	color: white;

	background: linear-gradient(
		270deg,
		rgba(84, 120, 246, 0.6) 0%,
		rgba(224, 194, 75, 0.6) 135%
	);
	user-select: none;

	img {
		width: 27px;
		height: 27px;
	}
}

@media screen and (min-width: 769px) {
	.btnFixed {
		top: 50%;
		left: 100%;

		flex-direction: row;
		justify-content: space-around;
		gap: 0;
		width: 230px;

		line-height: 100%;

		border-top-left-radius: 22px;
		border-top-right-radius: 22px;

		transform-origin: 0 40px;
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg) translateX(-30%);

		img {
			margin-top: 2px;

			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
	}
}
