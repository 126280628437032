.root {
	display: flex;
	padding: 100px 0;

	background: #020202;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 120px;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 80px;
}

.column {
	display: grid;
	grid-template-columns: 50% 50%;

	&Left {
		.title {
			position: sticky;
			top: 90px;
			padding-right: 50px;
			padding-top: 10px;
		}
	}

	&Right {
		display: flex;
		flex-direction: column;
		gap: 120px;
	}

	&Text {
		display: flex;
		flex-direction: column;
		gap: 50px;
	}

	&Profile {
		display: flex;
		flex-direction: column;
		gap: 50px;

		.text {
			padding-top: 0;
		}
	}
}

.title {
	font-family: e-Ukraine-Head;
	font-size: 48px;
	line-height: 67px;
	color: #ffffff;
}

.text {
	padding-top: 20px;

	font-family: e-Ukraine;
	font-size: 20px;
	line-height: 30px;
	color: #b5b5b5;

	&Blue {
		color: #426cff;
	}
}

.profile {
	display: flex;
	align-items: flex-start;
	gap: 30px;
	max-width: 650px;
	padding: 10px;

	background-color: #191818;
	border: 1px solid #323232;
	border-radius: 20px;

	&Image {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		max-width: 260px;

		background-color: #121212;
		border-radius: 20px;

		img {
			object-fit: contain;
		}
	}

	&Info {
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: 20px;
		max-width: 250px;
	}

	&Name {
		font-family: e-Ukraine-Head;
		font-size: 26px;
		line-height: 36px;
	}

	&Desc {
		font-family: e-Ukraine;
		font-size: 16px;
		line-height: 25px;
		color: #b5b5b5;
	}

	&Link {
		display: flex;
		gap: 25px;

		img {
			width: 30px;
			height: 30px;
		}
	}

	.text {
		color: #9595a1;
	}
}

@media screen and (max-width: 1280px) {
	.root {
		padding: 70px 0;
	}

	.column {
		&Left {
			.title {
				top: 82px;
			}
		}

		&Profile {
			align-items: center;

			.text {
				max-width: 650px;
			}
		}
	}

	.title {
		font-size: 40px;
		line-height: 140%;
	}

	.profile {
		align-items: center;
		gap: 20px;
		margin: 0 auto;

		&Info {
			gap: 15px;
		}

		&Link {
			img {
				width: 25px;
				height: 25px;
			}
		}
	}

	button {
		margin: 0 auto;
	}
}

@media screen and (max-width: 768px) {
	.root {
		padding: 50px 0;
	}

	.container {
		gap: 80px;
		padding: 0 30px;
	}

	.column {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 30px;

		&Left {
			.title {
				top: 82px;
				padding-right: 0;
			}
		}

		&Text {
			gap: 40px;
		}

		&Profile {
			.title {
				font-size: 24px;
				line-height: 33px;
			}
		}
	}

	.title {
		font-size: 30px;
		line-height: 41px;
		text-align: center;
	}

	.text {
		font-size: 16px;
		line-height: 24px;
		text-align: center;
	}

	.profile {
		gap: 20px;

		&Image {
			max-width: 230px;
		}

		&Name {
			font-size: 18px;
			line-height: 25px;
		}

		&Link {
			gap: 20px;

			img {
				width: 25px;
				height: 25px;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.container {
		padding: 0 20px;
	}

	.profile {
		align-items: center;
		gap: 15px;

		&Image {
			max-width: 140px;
		}

		&Info {
			gap: 10px;
		}

		&Name {
			font-size: 18px;
			line-height: 25px;
		}

		&Desc {
			font-size: 12px;
			line-height: 15px;
		}

		&Link {
			gap: 20px;

			img {
				width: 20px;
				height: 20px;
			}
		}
	}
}
