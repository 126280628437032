.footer {
	padding-bottom: 32px;

	&Bottom {
		width: 100%;
		padding: 40px 80px 15px 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		grid-area: bottom;

		&Info {
			display: flex;
			gap: 30px;

			a {
				font-family: 'e-Ukraine-Head-Medium';
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;

				color: var(--color--white);

				&:hover {
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
	}

	&Links {
		display: flex;
		align-items: center;
		gap: 30px;

		.icon {
			width: 22px;
		}
	}

	&Rights {
		padding: 0 80px;

		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		grid-area: rights;

		&Text {
			display: flex;
			align-items: center;
			gap: 5px;

			font-family: 'e-Ukraine-Light';
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 35px;

			color: #ffffff;
		}

		&Privacy {
			font-family: 'e-Ukraine';
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 20px;
			text-transform: uppercase;

			color: #ffffff;
		}
	}

	&.light {
		.footer {
			&Bottom {
				&Info {
					a {
						color: #7f8392;
					}
				}
			}

			&Rights {
				&Text {
					color: #191818;
				}

				&Privacy {
					color: #191818;
				}
			}
		}
	}

	@media screen and (max-width: 1280px) {
		&Bottom {
			flex-direction: column;
			padding: 0;

			&Info {
				width: 100%;
				padding: 26px 40px 40px;
				gap: 40px;

				.linkContact {
					font-size: 16px;
					line-height: 35px;
				}

				.email {
					font-size: 18px;
					line-height: 35px;
				}
			}
		}

		&Links {
			margin: 0 auto 41px 40px;
		}

		&Rights {
			padding: 0 40px;

			&Text {
				font-size: 13px;
				line-height: 53px;
			}

			&Privacy {
				font-size: 13px;
				line-height: 53px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		&Bottom {
			width: 100%;
			padding: 40px 30px 15px;

			&Info {
				padding: 26px 0 40px;
			}
		}
		&Links {
			margin: 0 auto 25px 0;
		}
		&Rights {
			padding: 0 30px 0;
		}
	}

	@media screen and (max-width: 480px) {
		&Bottom {
			padding: 40px 20px 15px;
		}
		&Rights {
			padding: 0 20px 0;
		}
	}
}
