.button {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 60px;
	height: 60px;
	background: #191818;
	border-radius: 50%;
	border: 1px solid rgba(255, 255, 255, 0.16);

	position: absolute;
	top: 50%;
	right: 10px;

	transform: translateY(-100%);
	z-index: 3;

	&.prev {
		transform: translateY(-100%) rotate(180deg);
		left: 10px;
		right: auto;
	}

	&:hover {
		border-color: #7f8392;
	}
}

@media screen and (max-width: 768px) {
	.button {
		width: 48px;
		height: 48px;

		right: 5px;
		left: auto;

		&.prev {
			left: 5px;
			right: auto;
		}
	}
}
