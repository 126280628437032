.situation {
	display: flex;
	align-items: center;
	padding: 120px 151px 120px 80px;
	margin: 0 auto;
	gap: 140px;

	background: #020202;

	color: #ffffff;
	max-width: 1440px;
}

.title {
	margin-bottom: 60px;

	font-family: 'e-Ukraine-Head', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 64px;
	line-height: 120%;

	color: #ffffff;
}

.description {
	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 150%;

	color: #b5b5b5;
}

.left {
	width: 60%;
}

.videoContainer {
	height: 100%;
}

.video {
	margin-left: auto;
	max-height: 100%;
	width: 26vw;
	border-radius: 20px;
}

@media screen and (max-width: 1280px) {
	.situation {
		padding: 39px 83px 80px 60px;
		gap: 100px;

		height: auto;
	}

	.title {
		margin-bottom: 25px;

		font-size: 37px;
		line-height: 136%;
	}

	.description {
		font-size: 16px;
		line-height: 150%;
	}

	.video {
		width: 31vw;
	}
}

@media screen and (max-width: 480px) {
	.situation {
		padding: 0 20px;
		flex-direction: column;
		gap: 70px;
		height: auto;
	}

	.left {
		width: 100%;
		text-align: center;
	}

	.video {
		padding: 0 30px;
		width: 100%;
	}
}
