.header-fixed {
	width: 100%;
	transition: all 0.1s;
}

.top-bar {
	position: fixed;
	height: 40px;
	z-index: 10;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	transition: all 0.1s;

	background: rgba(2, 2, 2, 0.7);
	backdrop-filter: blur(40px);

	img {
		display: none;
	}

	@media screen and (min-width: 768px) {
		height: 60px;

		&:hover {
			img {
				transform: rotate(180deg);
				transition: 0.5s;
			}
		}

		img {
			height: 20px;
			display: block;
			transform: rotate(135deg);
			transition: 0.5s;
		}
	}
}

.top-bar-text {
	padding: 10px 20px;
	display: inline-block;
	font-family: e-Ukraine;
	font-size: 14px;
	line-height: 17px;
	background: linear-gradient(90deg, #74b0f0 0%, #ffcf4d 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	@media screen and (min-width: 768px) {
		padding: 20px;
		font-size: 18px;
		line-height: 22px;
		text-transform: capitalize;
	}
}

.header-nav {
	position: relative;
	transition: all 0.25s;
}

.header-logo {
	position: fixed;
	top: 40px;
	left: 0;
	padding: 10px 20px;
	z-index: 10;
	transition: all 0.25s;

	&.scrolled {
		width: 100%;
		background: rgba(2, 2, 2, 0.7);
		backdrop-filter: blur(40px);
	}

	@media screen and (min-width: 768px) {
		top: 60px;
	}
}

.header-menu {
	position: fixed;
	top: 40px;
	right: 0;
	padding: 15px 20px;
	font-family: e-Ukraine-Head-Regular;
	font-size: 14px;
	line-height: 17px;
	color: #b5b5b5;
	text-align: right;
	text-transform: uppercase;
	z-index: 10;

	a {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	a:hover {
		color: white;
		text-decoration: underline;
	}

	@media screen and (min-width: 768px) {
		top: 60px;
	}
}
