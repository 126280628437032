.aboutUs {
	&Container {
		position: relative;

		padding: 120px 50px 200px;
		background: #fff;
		padding-bottom: 200px;
		background: #020202;

		min-height: 100vh;
		max-width: 1440px;
		margin: 0 auto;
	}

	.card {
		width: 283px;
		height: 375px;

		&.other {
			max-height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.name {
			margin-left: 10px;
			font-family: 'e-Ukraine', sans-serif;

			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 140%;
			display: flex;
			align-items: center;

			color: #ffffff;
		}

		.position {
			margin-left: 10px;
			font-family: 'e-Ukraine', sans-serif;

			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			min-height: 22px;
			display: flex;
			align-items: center;

			color: #7f8392;
		}

		img {
			border-radius: 20px;
			margin-bottom: 20px;
		}

		&:hover {
			border-color: #7f8392;
		}
	}

	&Title {
		margin-bottom: 70px;
		font-family: 'e-Ukraine-Head', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 48px;
		line-height: 140%;
		color: #ffffff;
		padding: 0 30px;
	}

	&Desc {
		font-size: 28px;
		line-height: 45px;
		color: #191818;

		a {
			color: #3873ee;
			text-decoration: none;

			font-family: 'StolzlMedium';
		}

		&.first {
			padding-right: 76px;
		}

		b {
			font-family: 'StolzlMedium';
		}
	}

	&Image {
		margin-bottom: 23px;
		width: 100%;
	}

	&Info {
		padding: 0 481px 250px 117px;

		display: flex;
		flex-direction: column;
		gap: 70px;
	}

	@media screen and (max-width: 768px) {
		&Container {
			padding: 120px 0 200px;
		}

		&Title {
			margin-bottom: 50px;

			font-size: 30px;
			line-height: 41px;
		}
	}

	@media screen and (max-width: 480px) {
		display: block;

		&Container {
			padding: 100px 0 50px;
		}

		.cardContainer {
			padding: 0 0 50px;

			width: 100%;
			display: flex;
			justify-content: center;
		}

		&Title {
			text-align: center;
		}

		&Desc {
			font-size: 18px;
			line-height: 32px;

			padding-right: 9px;

			&.first {
				padding-right: 9px;
			}
		}

		&Image {
			margin-bottom: 70px;
		}

		&Info {
			padding: 0 26px 100px 20px;

			gap: 30px;

			font-size: 18px;
			line-height: 32px;
		}
	}

	@media screen and (max-width: 768px) {
		&Container {
			min-height: auto;
			padding-bottom: 117px;
		}
	}
}

.memberPhoto {
	width: 263px;
	height: 250px;
	box-shadow: inset 1px 1px 1px 1px #191818;
}

.card {
	padding: 10px 10px 43px;
	display: flex;
	flex-direction: column;

	background: #191818;
	border: 1px solid #323232;
	border-radius: 20px;
}

.slide {
	width: auto;
}
