.section-media {
	.section-title {
		margin-bottom: 20px;
		border: none;
		width: calc(100% - 150px);
	}

	@media screen and (min-width: 768px) {
		.section-title {
			margin-bottom: 40px;
		}
	}
}

.media-card {
	width: 280px;

	@media screen and (min-width: 768px) {
		width: 340px;
	}
}

.media-image {
	margin-bottom: 20px;
	height: 140px;
	width: 280px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #121212;

	img {
		width: 50%;
	}

	@media screen and (min-width: 768px) {
		height: 200px;
		width: 340px;
	}
}

.media-desc {
	font-family: e-Ukraine-Head-Medium;
	font-size: 16px;
	line-height: 23px;
}
