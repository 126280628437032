.sidebar-container {
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	top: 100%;
	left: 0;
	background: var(--color--black);
	transition: all 1s;

	&.open {
		top: 0;
	}
}

.sidebar {
	height: 100%;
	padding: 111px 20px 0;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 481px) {
		padding: 162px 100px 63px;

		justify-content: space-between;
		background: var(--color--black);
	}

	.content-line {
		border-top: 1px solid var(--color--white);
		box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.45);

		@media screen and (max-width: 480px) {
			margin-bottom: 30px;
		}
	}

	&__content {
		flex: 1;
		display: flex;

		.sidebar-options {
			width: 828px;
			flex: 2.2;

			display: flex;
			flex-direction: column;
			justify-content: center;

			.sidebar-option {
				margin-bottom: 38px;
				display: grid;
				grid-template-columns: 60px 1fr;
				align-items: center;

				@media screen and (max-width: 480px) {
					margin-bottom: 25px;
				}

				&__number {
					font-size: 16px;
					line-height: 16px;

					@media screen and (min-width: 481px) {
						font-size: 30px;
						line-height: 42px;
					}
				}

				> a {
					text-decoration: none;

					div span {
						display: flex;
						font-family: 'StolzlBold';

						font-size: 25px;
						line-height: 25px;
						text-transform: uppercase;
						color: var(--color--white);
						transition: all 0.35s;

						@media screen and (min-width: 481px) {
							font-size: 60px;
							line-height: 70px;
						}

						svg {
							position: relative;

							margin-left: 10px;

							width: 25px;
							height: 25px;

							stroke: var(--color--white);
							transition: all 0.35s;

							@media screen and (min-width: 481px) {
								margin-left: 0;

								width: 49px;
								height: 49px;
							}
						}
					}

					&:hover,
					&:target {
						div span {
							cursor: pointer;
							-webkit-text-stroke: 1px var(--color--pink-light);
							text-shadow: 0px 0px 4px var(--color--pink-light);
							color: var(--color--black);
						}

						svg {
							stroke: var(--color--pink-light);
							transform: rotate(45deg);
							margin-left: 11px;
						}
					}
				}

				> div {
					span {
						font-family: 'StolzlBold';

						font-size: 25px;
						line-height: 25px;
						text-transform: uppercase;
						color: var(--color--white);
						transition: all 0.35s;

						@media screen and (min-width: 481px) {
							font-size: 60px;
							line-height: 70px;
						}

						svg {
							position: relative;

							margin-left: 10px;

							width: 25px;
							height: 25px;

							stroke: var(--color--white);
							transition: all 0.35s;

							@media screen and (min-width: 481px) {
								margin-left: 0;

								width: 49px;
								height: 49px;
							}
						}
					}

					&:hover,
					&:target {
						span {
							cursor: pointer;
							-webkit-text-stroke: 1px var(--color--pink-light);
							text-shadow: 0px 0px 4px var(--color--pink-light);
							color: var(--color--black);
						}

						svg {
							stroke: var(--color--pink-light);
							transform: rotate(45deg);
							margin-left: 11px;
						}
					}
				}
			}
		}

		.sidebar-image {
			max-height: 561px;

			flex: 1;
			opacity: 0;
			transition: all 0.15s;

			&.show {
				opacity: 1;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.footer {
		margin: 20px 0 12px;

		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@media screen and (min-width: 481px) {
			margin-top: 141px;
		}

		&__rights,
		&__title,
		&__adress {
			margin: 0;

			font-size: 14px;
			line-height: 30px;

			@media screen and (min-width: 481px) {
				font-size: 16px;
			}
		}

		&__title,
		&__adress {
			@media screen and (max-width: 480px) {
				display: none;
			}
		}
	}
}
