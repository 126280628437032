.header {
	padding: 10px 80px;

	height: 90px;
	display: flex;
	align-items: center;

	background: #020202;
	backdrop-filter: blur(40px);

	z-index: 10;

	position: sticky;
	top: 0;
	left: 0;
	right: 0;

	&Logo {
		width: 24px;
	}

	&Container {
		padding-left: 155px;
		padding-right: 20px;

		display: flex;
		align-items: center;
	}

	&Links {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;

		a {
			white-space: nowrap;

			font-family: 'e-Ukraine', sans-serif;

			font-style: normal;
			font-weight: normal;

			font-size: 16px;
			line-height: 19px;

			cursor: pointer;

			color: #ffffff;
			text-decoration: none;

			margin-right: 36px;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&Separator {
		background-color: #323232;

		margin: 0 56px;

		height: 24px;
		width: 1px;
	}

	&Icons {
		display: flex;
		align-items: center;

		a {
			margin-right: 36px;
			height: 22px;
		}
	}

	.mintControls {
		margin-left: auto;
		display: flex;
		align-items: center;
		gap: 28px;
	}
}

@media screen and (max-width: 1280px) {
	.header {
		padding: 10px 40px;

		height: 82px;

		&Container {
			margin-left: auto;
			padding-left: 20px;
			padding-right: 20px;
		}
		&Links {
			display: none;
			a {
				margin-right: 26px;
			}
		}
		&Separator {
			display: none;
		}

		&Icons {
			a {
				margin-right: 30px;
			}
		}
		.mintControls {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: 768px) {
	.header {
		padding-top: 80px;
		padding-bottom: 40px;

		.mintControls {
			display: none;
		}
		&Icons {
			a {
				&:last-child {
					margin-right: 0;
				}
			}
		}
		&Container {
			margin-left: auto;
			padding-right: 0;
		}
		&Logo {
			width: 22px;
		}
	}
}

@media screen and (max-width: 480px) {
	.header {
		padding: 70px 20px 30px;

		height: 70px;

		&Links {
			display: none;
		}

		&Separator {
			display: none;
		}
	}
}
