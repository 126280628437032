.slider {
	position: relative;

	padding: 139px 0 268px;
	background: var(--color--grey-light);

	transition: all 0.45s;

	@media screen and (max-width: 480px) {
		padding: 70px 0 132px;
	}

	&__image {
		cursor: pointer;

		z-index: 3;
		position: absolute;

		width: 348.52px;
		height: 467.37px;

		object-fit: contain;

		opacity: 0;

		transition: opacity 0.35s;
		transform: translate(-15%, -50%);

		pointer-events: none;

		@media screen and (max-width: 480px) {
			display: none;
		}
	}

	&__title {
		margin: 0 102px 46px 208px;
		padding-top: 24px;
		border-top: 1px solid rgba(211, 211, 211, 0.4);

		font-size: 20px;
		line-height: 30px;
		color: #c1c1c1;

		@media screen and (max-width: 480px) {
			margin: 0 20px 43px;
			padding-top: 15px;

			font-size: 15px;
		}
	}

	.slick-slider {
		height: 100%;

		.slick-list {
			height: 100% !important;

			.slick-track {
				height: 100%;

				.slick-slide div {
					height: 100%;

					div {
						position: relative;
						height: 100%;
						display: flex !important;
						align-items: center;
						justify-content: space-evenly;

						@media screen and (min-width: 481px) {
							&:hover {
								cursor: pointer;

								.slider-text {
									cursor: pointer;
									-webkit-text-stroke: 1px var(--color--pink-light);
									text-shadow: 0px 0px 4px var(--color--pink-light);
								}
							}
						}
					}
				}
			}
		}

		.slider-text {
			-webkit-text-stroke: 1px #c1c1c1;
			text-shadow: 0px 0px 20px var(--color--white);
			font-family: 'StolzlBold';
			font-size: 90px;
			line-height: 172px;
			text-transform: uppercase;

			transition: all 0.45s;

			@media screen and (max-width: 1280px) {
				font-size: 80px;
			}

			@media screen and (max-width: 480px) {
				font-size: 55px;
				line-height: 96px;
			}

			@media screen and (max-width: 320px) {
				font-size: 42px;
				line-height: 82px;
			}
		}
	}

	&--dark {
		padding: 0;
		background: var(--color--black);

		.slick-slide div div {
			pointer-events: none;
		}

		.slider-text {
			-webkit-text-stroke: 1px #fefefe;
			text-shadow: none !important;
			color: var(--color--black);
		}
	}
}
