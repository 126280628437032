.contact-info {
	padding: 20px;

	@media screen and (min-width: 768px) {
		padding: 20px 160px;
	}
}

.contact-title {
	padding: 15px 0;
	font-family: e-Ukraine-Head-Medium;
	font-size: 28px;
	line-height: 44px;
	text-transform: uppercase;
	border-bottom: 1px solid #6b6b6b;

	@media screen and (min-width: 768px) {
		display: flex;
		justify-content: space-between;
		font-size: 70px;
		line-height: 84px;
	}
}

.contact-text {
	margin-top: 50px;
	font-family: e-Ukraine-Thin;
	font-size: 14px;
	line-height: 23px;

	@media screen and (min-width: 768px) {
		margin: 120px 0;
		max-width: 600px;
		font-size: 24px;
		line-height: 40px;
	}
}

.contact-link {
	margin: 40px 0;
	gap: 20px;
	display: flex;
	flex-direction: column;
	font-family: e-Ukraine-Head-Medium;
	font-size: 20px;
	line-height: 24px;
	text-transform: uppercase;

	a {
		gap: 10px;
		display: flex;
		align-items: center;

		&:hover {
			text-decoration: underline;

			img {
				transform: rotate(180deg);
				transition: 0.5s;
			}
		}
	}

	img {
		height: 20px;
		transform: rotate(135deg);
		transition: 0.5s;
	}

	@media screen and (min-width: 768px) {
		margin-bottom: 180px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 50px;
		font-size: 32px;
		line-height: 42px;

		img {
			height: 30px;
		}
	}
}

.footer-bottom {
	padding: 10px 20px;

	@media screen and (min-width: 768px) {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
	}
}

.social-list {
	margin-bottom: 30px;
	gap: 30px;
	display: flex;

	a {
		padding: 10px;
		height: 40px;
		width: 40px;
		display: flex;
		background: #191818;
		border: 1px solid #323232;
		border-radius: 50%;

		&:hover {
			border-color: #b5b5b5;
		}
	}
}

.copywriting-text {
	margin-bottom: 30px;
	gap: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: e-Ukraine-Light;
	font-size: 12px;
	line-height: 35px;
	text-transform: uppercase;

	p {
		gap: 5px;
		display: flex;
		align-items: center;
	}

	@media screen and (min-width: 768px) {
		justify-content: start;
	}
}
