.root {
	background: #020202;

	display: flex;
	align-items: center;

	padding: 120px 80px 50px;

	background-image: url('./assets/faq-bg.png');
	background-size: 100% 80%;
	background-repeat: no-repeat;
}

.container {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;

	display: grid;
	grid-template-columns: 50% 50%;
}

.faqTitleLG {
	position: sticky;
	top: 90px;

	padding-top: 10px;
	padding-right: 50px;
	margin-bottom: 24px;

	font-family: 'e-Ukraine-Head';
	font-size: 64px;
	line-height: 120%;

	color: #ffffff;
}

.faqTitleSM {
	display: none;

	padding-right: 0;
	text-align: center;
	width: 100%;

	margin-bottom: 40px;

	font-family: 'e-Ukraine-Head';
	font-size: 37px;
	line-height: 136%;

	color: #ffffff;
}

.accordion {
	padding-top: 20px;

	& > div:hover {
		border-color: #7f8392;
	}

	svg {
		color: #3873ee;
	}
}

@media screen and (max-width: 1280px) {
	.faqTitleLG {
		font-size: 37px;
		line-height: 136%;
	}
}

@media screen and (max-width: 768px) {
	.root {
		padding: 40px 30px 30px;
		background-size: 100% 100%;
	}

	.container {
		width: 100%;

		display: flex;
		flex-direction: column;
	}

	.faqTitleLG {
		display: none;
	}

	.faqTitleSM {
		display: block;
	}

	.accordion {
		width: 100%;
	}
}

@media screen and (max-width: 480px) {
	.root {
		padding: 40px 20px 30px;
	}
}
