li {
	list-style: none;
}

a.anchor {
	display: block;
	position: relative;
	top: -80px;
	visibility: hidden;
}

.page-container {
	display: flex;
	flex-direction: column;
	font-family: 'e-Ukraine', sans-serif;
	background-color: #0c0c0c;
}

.page-header {
	min-height: 100vh;
	height: 100%;
	background: linear-gradient(
			to bottom,
			#050505 0.18%,
			rgba(23, 20, 12, 0) 32.99%
		),
		linear-gradient(
			to top,
			#0c0c0c 6.89%,
			rgba(13, 13, 12, 0.885731) 35.73%,
			rgba(23, 20, 12, 0) 100%
		),
		url('./img/bg-ua.jpg') center no-repeat;

	background-size: cover;
}

.header-promo {
	padding: 30px 20px 100px;
	margin-top: 160px;

	@media screen and (min-width: 768px) {
		padding: 0 160px 240px;
	}
}

.promo-hello {
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;

	@media screen and (min-width: 768px) {
		font-size: 16px;
		line-height: 19px;
	}
}

.promo-title {
	margin: 40px 0;
	font-family: e-Ukraine-Head-Medium;
	font-size: 30px;
	line-height: 40px;

	@media screen and (min-width: 768px) {
		max-width: 960px;
		font-size: 70px;
		line-height: 84px;
	}
}

.promo-text {
	color: #b5b5b5;
	font-family: e-Ukraine-Thin;
	font-size: 16px;
	line-height: 24px;

	@media screen and (min-width: 768px) {
		max-width: 740px;
		font-size: 28px;
		line-height: 40px;
	}
}

.section {
	padding: 20px;
	margin-bottom: 80px;

	@media screen and (min-width: 768px) {
		padding: 20px 160px;
		margin-bottom: 180px;
	}
}

.section-title {
	padding: 15px 0;
	font-family: e-Ukraine-Head-Medium;
	font-size: 28px;
	line-height: 44px;
	text-transform: uppercase;
	border-bottom: 1px solid #6b6b6b;

	p {
		margin-top: 15px;
		font-family: e-Ukraine-Thin;
		font-size: 14px;
		line-height: 21px;
		text-align: left;
	}

	@media screen and (min-width: 768px) {
		display: flex;
		justify-content: space-between;
		font-size: 70px;
		line-height: 84px;

		p {
			font-size: 19px;
			line-height: 21px;
		}
	}
}

.section-subtitle {
	font-family: e-Ukraine-Head-Medium;
	font-size: 28px;
	line-height: 44px;
	text-transform: uppercase;

	@media screen and (min-width: 768px) {
		font-size: 50px;
		line-height: 60px;
	}
}

.section-text {
	margin-top: 50px;
	font-family: e-Ukraine-Thin;
	font-size: 14px;
	line-height: 23px;

	@media screen and (min-width: 768px) {
		margin-top: 120px;
		max-width: 600px;
		font-size: 28px;
		line-height: 40px;
	}
}

.section-products {
	@media screen and (min-width: 768px) {
		margin-bottom: 130px;

		.section-title {
			margin-bottom: 120px;
		}
	}
}

.section-product {
	margin: 50px 0;
	display: flex;
	flex-direction: column-reverse;
	border-bottom: 1px solid #6b6b6b;

	transition: all 0.35s;

	// cursor: pointer;

	@media screen and (min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 135px;
	}

	&.product-web {
		// cursor: auto;

		// &:hover {
		// 	border-bottom: 1px solid #6b6b6b;

		// 	.product-title {
		// 		text-decoration: none;
		// 	}
		// }
	}

	// &:hover {
	// 	border-bottom: 1px solid #ffffff;

	// 	.product-title {
	// 		text-decoration: underline;
	// 	}
	// }
}

.product-tag {
	gap: 15px;
	display: flex;

	li {
		padding: 8px 16px;
		font-family: e-Ukraine-Head-Regular;
		font-size: 12px;
		line-height: 100%;
		background-color: #191818;
		border: 1px solid #323232;
		border-radius: 50px;
		text-transform: uppercase;
	}

	@media screen and (min-width: 768px) {
		li {
			font-size: 14px;
			line-height: 17px;
		}
	}
}

.product-title {
	margin: 25px 0;
	font-family: e-Ukraine-Head-Medium;
	font-size: 24px;
	line-height: 40px;
	text-transform: uppercase;

	.hashtag {
		text-transform: none;
	}

	@media screen and (min-width: 768px) {
		font-size: 35px;
		line-height: 42px;
	}
}

.product-desc {
	font-family: e-Ukraine-Thin;
	font-size: 14px;
	line-height: 23px;

	span {
		font-family: e-Ukraine-Head;
	}
}

.product-image {
	margin: 25px 0;
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: rgba(97, 97, 97, 0.09);

	img {
		object-fit: cover;
	}

	@media screen and (min-width: 768px) {
		margin-top: 0;
	}
}

.product-info {
	padding-left: 25px;
	margin-bottom: 25px;

	li {
		text-transform: uppercase;
		list-style: disc;
	}
}
