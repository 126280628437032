.container {
	width: 100%;
	max-width: 1440px;
	padding: 120px 50px;
	margin: 0 auto;
}

.heading {
	font-family: 'e-Ukraine-Head', sans-serif;

	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 140%;

	color: #ffffff;

	margin-bottom: 70px;
	padding: 0 30px;
}

.slideContainer {
	padding: 10px 10px 35px 10px;
	height: 100%;
	background: #191818;
	border: 1px solid #323232;
	border-radius: 20px;
	width: 283px;
	height: 416px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	&:hover {
		text-decoration: none !important;
		border-color: #7f8392;
	}
}

.slideImage {
	border-radius: 20px;
	width: 100%;
	height: 165px;
	object-fit: cover;

	margin-bottom: 20px;
}

.slideDescription {
	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	&:hover {
		text-decoration: none;
	}

	color: #ffffff;
}

.slideBottom {
	padding: 0 15px;
	display: flex;
}

.slideCompanyImage {
	margin-right: 10px;
	border-radius: 9999px;
}

.slideCompanyTitle {
	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 140%;
	display: flex;
	align-items: center;

	color: #ffffff;
}

@media screen and (max-width: 768px) {
	.container {
		padding: 40px 0 87px;
	}

	.heading {
		margin-bottom: 60px;
		font-size: 30px;
		line-height: 136%;
		text-align: left;
	}
}

@media screen and (max-width: 480px) {
	.container {
		padding: 100px 0 100px;

		h2 {
			padding: 0 20px;
		}
	}
}
