* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	width: 100%;
	height: 100%;

	background-color: var(--color--black) !important;
	color: var(--color--white);
	font-family: 'StolzlBook', sans-serif;
}

.slick-prev,
.slick-next {
	display: none !important;
}

ul {
	padding-inline-start: 20px;
}
