.values {
	// margin: 0 0 0 20px;
	margin-left: 20px;

	padding-top: 160px;

	@media screen and (min-width: 481px) {
		margin-left: 0;

		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}

	@media screen and (min-width: 1024px) {
		margin-left: 0;

		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}

	.text-heading {
		padding-bottom: 20px;

		display: flex;
		flex-direction: column;
		letter-spacing: -0.4px;

		border-bottom: 1px solid rgba(255, 255, 255, 0.45);

		@media screen and (min-width: 481px) {
			margin-right: 0;
			margin-bottom: 77px;
			padding: 0;

			flex-direction: row;
			padding: 0 100px;

			border-bottom: none;
		}

		@media screen and (min-width: 1024px) {
			margin-right: 0;
			margin-bottom: 82px;
			padding: 0;

			flex-direction: row;
			padding: 0 100px;
		}

		.text-heading__number {
			font-size: 30px;
			margin-right: 63px;
			font-size: 15px;
			line-height: 42px;

			@media screen and (min-width: 481px) {
				padding-top: 20px;

				font-size: 30px;
			}

			@media screen and (min-width: 1024px) {
				padding-top: 20px;

				font-size: 30px;
			}
		}

		div {
			display: flex;
			flex-direction: column;

			font-size: 35px;
			line-height: 40px;
			text-transform: uppercase;

			span {
				z-index: 1;
				font-family: 'StolzlBold';
				margin-bottom: 6px;

				@media screen and (min-width: 481px) {
					font-size: 90px;
					line-height: 95px;
				}

				@media screen and (min-width: 1024px) {
					font-size: 90px;
					line-height: 95px;
				}
			}
		}
	}

	.line {
		margin-bottom: 0;
		margin-right: 0;
		border-top: 1px solid var(--color--white);
	}

	.values-slider {
		padding: 20px 0 100px;
		.value-slide {
			max-width: 340px;

			&__image {
				margin: 0 0 14px;
				width: 340px;
				height: 210px;

				object-fit: contain;
			}

			&__title {
				margin: 0 0 24px;

				font-size: 28px;
				line-height: 37px;

				display: flex;
				align-items: center;

				font-family: 'StolzlMedium';
				color: #ffffff;

				opacity: 0.95;
			}

			&__source {
				margin: 0;

				font-weight: bold;
				font-size: 16px;
				line-height: 126%;

				letter-spacing: -0.016em;

				color: #c1c1c1;

				opacity: 0.95;
			}

			&__description {
				max-width: 340px;
				font-size: 15px;
				line-height: 25px;

				opacity: 0.9;
			}
		}
	}
}

.values-content {
	flex: 1;
	margin: 0 100px 0 196px;
	display: grid;
	grid-template-columns: 1.36fr 1fr;

	padding: 20px 0;

	border-top: 1px solid rgba(255, 255, 255, 0.45);

	.values-list {
		padding-bottom: 74px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.values-option {
			font-family: 'StolzlRegular';
			font-size: 27px;

			opacity: 0.95;

			height: 60px;

			span {
				user-select: none;

				font-size: 27px;
				line-height: 60px;

				transition: all 0.35s;
				&.active {
					position: relative;
					cursor: pointer;
					-webkit-text-stroke: 1px var(--color--pink-light);
					text-shadow: 0px 0px 4px var(--color--pink-light);
					font-size: 35px;
					line-height: 60px;

					color: var(--color--black);

					&:after {
						position: absolute;
						content: '';
						width: 37.5px;
						height: 1px;
						background: var(--color--pink-light);
						top: 50%;
						right: -15%;
					}
				}
			}

			// &:nth-child(3),
			// &:nth-child(5) {
			// 	span {
			// 		&.active {
			// 			font-size: 35px;
			// 		}
			// 	}
			// }
		}
	}

	.values-description {
		height: 561px;

		.description-image {
			margin: 0 0 68px;

			width: 340px;
			height: 210px;

			@media screen and (min-width: 481px) {
				width: 460px;
				height: 300px;
			}
			img {
				width: 100%;
			}
		}

		.description__text {
			font-size: 20px;
			line-height: 35px;
			opacity: 0.95;
		}

		.description__source {
			font-weight: bold;
			font-size: 16px;
			line-height: 126%;

			letter-spacing: -0.016em;

			color: #c1c1c1;

			opacity: 0.95;
		}
	}
}
