.root {
	padding: 120px 0;

	background: #020202;

	min-height: calc(100vh - 90px);

	display: flex;
	align-items: center;

	background-image: url('./assets/purpose-bg.png');
	background-size: cover;
}

.container {
	max-width: 960px;
	width: 100%;

	margin: 0 auto;
}

.title {
	margin-bottom: 70px;

	font-family: 'e-Ukraine-Head', sans-serif;

	font-style: normal;
	font-weight: bold;
	font-size: 64px;
	line-height: 120%;

	text-align: center;

	color: #ffffff;
}

.description {
	padding: 0 60px;

	font-family: 'e-Ukraine', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 150%;

	text-align: center;
	color: #a5a99f;
}

.link {
	color: #426cff !important;
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}

.whiteText {
	color: #fff;
}

.blueText {
	color: #426cff;
}

@media screen and (max-width: 1280px) {
	.root {
		padding: 60px 0 80px;
		min-height: calc(100vh - 82px);
	}

	.container {
		max-width: 800px;
		padding: 0 40px;
	}

	.title {
		margin-bottom: 25px;

		font-size: 37px;
		line-height: 136%;
	}

	.description {
		padding: 0 40px;

		font-size: 16px;
		line-height: 150%;
	}
}

@media screen and (max-width: 768px) {
	.root {
		padding: 40px 0 80px;
		min-height: calc(100vh - 70px);
	}

	.container {
		padding: 0 27px;
		max-width: 600px;
	}

	.title {
		margin-bottom: 25px;
	}

	.description {
		padding: 0;
	}
}
