.card {
	height: 503px;

	overflow: hidden;
	cursor: pointer;
	position: relative;

	display: grid !important;

	@media screen and (min-width: 481px) {
		height: 100%;
	}

	&-link {
		@media screen and (min-width: 481px) {
			height: 564px;
		}
	}

	&--slide {
		// width: 90%;
		// height: 80%;
		margin: 0 24px;

		.card__image {
			// width: 100%;
			// height: 100%;
			object-fit: contain;
			// transition: all 0.5s;
		}

		.details {
			padding: 10px 54px 23px;
		}
	}

	.card__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		transition: 0.5s;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: all 0.5s;
		}
	}

	&:hover {
		.card__image {
			transition: all 0.9s;
		}
	}

	.details {
		position: absolute;
		bottom: -100px;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 10px 30px 23px;
		z-index: 1;
		color: var(--color--white);
		transition: all 0.5s;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		&__title {
			margin: 0;

			font-family: 'StolzlMedium';
			font-size: 25px;
			line-height: 40px;
			text-transform: uppercase;
			color: var(--color--grey-lighten);

			@media screen and (min-width: 481px) {
				font-size: 40px;
				line-height: 49px;
			}
		}

		&__description {
			margin-top: 20px;

			display: flex;
			justify-content: space-between;
			border-top: 1px solid var(--color--white);
			font-size: 16px;
			line-height: 30px;

			box-shadow: inset 0px -150px 50px -5px #000;

			@media screen and (min-width: 481px) {
				margin-top: 49px;
			}

			@media screen and (min-width: 1024px) {
				margin-top: 49px;
			}

			span {
				margin-top: 12px;
				font-size: 14px;
				line-height: 30px;

				opacity: 0.7;

				@media screen and (min-width: 481px) {
					font-size: 16px;
					line-height: 30px;
				}

				@media screen and (min-width: 1024px) {
					font-size: 16px;
					line-height: 30px;
				}
			}
		}
	}

	&--mobile {
		margin: 0 19px 40px;
	}

	&:hover,
	&--mobile {
		.details {
			z-index: 3;
			bottom: 0;

			box-shadow: inset 0px -150px 50px -5px #000;
		}
	}
}
