.root {
	position: relative;
	background: #020202;
}

.container {
	max-width: 1440px;
	margin: 0 auto;

	padding: 120px 80px;

	min-height: calc(100vh - 90px);

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.info {
	min-width: 500px;

	display: flex;
	flex-direction: column;

	padding-right: 48px;
}

.infoTitle {
	margin-bottom: 50px;

	font-weight: bold;

	font-family: 'e-Ukraine-Head', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 58px;
	line-height: 120%;

	// text-transform: capitalize;

	color: #ffffff;
}

.infoDesc {
	max-width: 569px;
	margin-bottom: 50px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 150%;

	color: #b5b5b5;
}

.infoBtn {
	display: flex;
}

.donations {
	width: 100%;
	min-width: 500px;
	max-width: 640px;

	padding: 50px 41.5px;

	background: #191818;
	border-radius: 50px;

	display: flex;
	flex-direction: column;
}

.wrapper {
	margin-bottom: 30px;

	display: grid;

	grid-template-columns: 194px 1fr;
	grid-column-gap: 51px;
}

.flag {
	width: 100%;
}

.status {
	display: flex;
	flex-direction: column;
}

.statusTitle {
	margin-bottom: 20px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 150%;

	text-transform: uppercase;
	color: #426cff;
}

.statusValue {
	display: flex;
	align-items: center;

	margin-bottom: 24px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	line-height: 111%;
	text-transform: uppercase;
	color: #ffffff;
}

.ethereum {
	height: 27px;
	padding: 0 5px;
}

.statusText {
	margin-bottom: 17px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: 200;
	font-size: 20px;
	line-height: 139%;
	text-transform: uppercase;

	color: #ffffff;
}

.statusTotal {
	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: bold;
	font-size: 35px;
	line-height: 100%;
	text-transform: uppercase;
	color: #ffffff;
}

.progress {
	margin-bottom: 34px;
}

.progressBar {
	position: relative;

	margin-bottom: 13px;
}

.progressImage {
	width: 77px;
	position: absolute;
	left: 8px;

	top: 50%;
	transform: translateY(-50%);
}

.progressTrack {
	padding: 8px 8px 8px 60px;
	margin: 0 8px 0 24px;

	background: rgba(255, 255, 255, 0.1);
	border-radius: 15px;
}

.progressThumb {
	height: 27px;

	width: 30%;

	background: #426cff;
	border-radius: 7px;
}

.progressValues {
	margin-left: 95px;

	display: flex;
	align-items: center;

	justify-content: space-between;
}

.progressValue {
	padding: 0 10px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 20px;
	text-transform: uppercase;

	color: #b5b5b5;

	&.first {
		margin-right: 30px;
	}
}

.progressTotal {
	padding: 0 10px;

	font-family: 'e-Ukraine', sans-serif;

	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 139%;

	text-transform: uppercase;
	color: #ffffff;
}

.footer {
	padding-left: 50px;
	padding-right: 50px;

	font-family: 'e-Ukraine', sans-serif;
	font-size: 14px;
	line-height: 25px;

	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}

.greyText {
	color: #b5b5b5;
}

@media screen and (max-width: 1280px) {
	.root {
		margin-top: -82px;
	}

	.container {
		min-height: calc(100vh - 82px);
		padding: 80px 40px;

		max-width: 1024px;
	}

	.info {
		min-width: auto;
		max-width: 430px;

		display: flex;
		flex-direction: column;

		padding-right: 38px;
	}

	.infoTitle {
		margin-bottom: 25px;

		font-size: 37px;
	}

	.infoDesc {
		margin-bottom: 30px;
		font-size: 16px;
	}

	.donations {
		width: 100%;
		min-width: 340px;
		max-width: 450px;

		padding: 30px 31.5px;

		border-radius: 25px;
	}

	.wrapper {
		margin-bottom: 30px;

		grid-template-columns: 117px 1fr;
		grid-column-gap: 30px;
	}

	.statusTitle {
		margin-bottom: 10px;
		font-size: 14px;
	}

	.statusValue {
		margin-bottom: 12px;

		font-size: 16px;
	}

	.ethereum {
		height: 14px;
		padding: 0 3px;
	}

	.statusText {
		margin-bottom: 10px;
		font-size: 12px;
	}

	.statusTotal {
		font-size: 24px;
	}

	.progress {
		margin-bottom: 25px;
	}

	.progressBar {
		margin-bottom: 11px;
	}

	.progressImage {
		width: 50px;
		left: 8px;
		top: 50%;
		transform: translateY(-50%);
	}

	.progressTrack {
		padding: 4px 6px 4px 32px;
		margin: 0 0 0 24px;

		background: rgba(255, 255, 255, 0.1);
		border-radius: 15px;
	}

	.progressThumb {
		height: 13px;
		border-radius: 4px;
	}

	.progressValues {
		margin-left: 64px;
	}

	.progressValue {
		padding: 0 5px;
		font-size: 14px;

		&.first {
			margin-right: 25px;
		}
	}

	.progressTotal {
		padding: 0 5px;
		font-size: 14px;
	}

	.footer {
		padding: 0;

		font-size: 12px;
		line-height: 18px;
	}
}

@media screen and (max-width: 768px) {
	.root {
		margin-top: 0px;
	}

	.container {
		min-height: calc(100vh - 70px);
		padding: 40px 20px;
		max-width: 480px;

		display: flex;
		flex-direction: column;

		justify-content: center;
	}

	.info {
		min-width: auto;
		max-width: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		padding-right: 0;
	}

	.infoTitle {
		text-align: center;
		margin-bottom: 25px;

		font-size: 37px;
	}

	.infoDesc {
		text-align: center;
		max-width: 400px;
		margin-bottom: 40px;
		font-size: 16px;
	}

	.infoBtn {
		width: 100%;
		max-width: 350px;
		margin-bottom: 70px;

		button {
			width: 100%;
		}
	}

	.donations {
		min-width: auto;
		padding: 30px 24px;

		border-radius: 25px;
	}

	.wrapper {
		margin-bottom: 30px;

		grid-template-columns: 97px 1fr;
		grid-column-gap: 25px;
	}

	.statusTitle {
		margin-bottom: 10px;
		font-size: 14px;
	}

	.statusValue {
		margin-bottom: 12px;
		font-size: 16px;
	}

	.ethereum {
		height: 14px;
		padding: 0 3px;
	}

	.statusText {
		margin-bottom: 10px;
		font-size: 12px;
	}

	.statusTotal {
		font-size: 24px;
	}

	.progress {
		margin-bottom: 25px;
	}

	.progressBar {
		margin-bottom: 11px;
	}

	.progressImage {
		width: 50px;
		left: 8px;
		top: 50%;
		transform: translateY(-50%);
	}

	.progressTrack {
		padding: 4px 6px 4px 32px;
		margin: 0 0 0 24px;

		background: rgba(255, 255, 255, 0.1);
		border-radius: 15px;
	}

	.progressThumb {
		height: 13px;
		border-radius: 4px;
	}

	.progressValues {
		margin-left: 64px;
	}

	.progressValue {
		padding: 0 5px;
		font-size: 14px;

		&.first {
			margin-right: 25px;
		}
	}

	.progressTotal {
		padding: 0 5px;
		font-size: 14px;
	}

	.footer {
		font-size: 11px;
	}
}

@media screen and (max-width: 480px) {
	.statusTitle {
		font-size: 12px;
		line-height: 14px;
	}
	.progressValue {
		padding: 0 5px;
		font-size: 14px;

		&.first {
			padding-left: 0;
			margin-right: 5px;
		}
	}
}
