.button {
	width: 43px;
	height: 43px;
	border-radius: 50%;
	z-index: 2;

	position: absolute;
	top: 14%;
	right: 7px;

	transform: translateY(-100%);
	z-index: 3;

	&.prev {
		transform: translateY(-106%) rotate(180deg);
		right: 100px;
	}
}

@media screen and (max-width: 768px) {
	.button {
		display: none;
		width: 30px;
		height: 30px;

		right: 5px;
		left: auto;

		&.prev {
			right: 50px;
		}
	}
}
