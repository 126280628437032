.styled-swiper {
	.swiper-wrapper {
		align-items: flex-end;
		padding: 0 30px;
	}

	&::before {
		content: '';
		display: block;
		left: 0;
		position: absolute;
		top: 0;
		height: 100%;
		width: 60px;
		z-index: 2;
	}

	&::after {
		content: '';
		display: block;
		right: 0;
		position: absolute;
		top: 0;
		height: 100%;
		width: 60px;
		z-index: 2;
	}

	.swiper-pagination {
		bottom: 0 !important;
	}

	.swiper-button-next,
	.swiper-button-prev {
		background: #3873ee;
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.swiper-button-next {
		right: 0;
		top: 25px;
		&::after {
			color: white;
			content: '→';
			font-size: 35px;
			color: white;
		}
	}

	.swiper-button-prev {
		left: 75%;
		top: 25px;
		&::after {
			color: white;
			content: '←';
			font-size: 35px;
			color: white;
		}
	}

	&.galery {
		.swiper-button-next {
			left: 85%;
			top: 25px;
			&::after {
				color: white;
				content: '→';
				font-size: 35px;
				color: white;
			}
		}
	}

	@media screen and (max-width: 480px) {
		.swiper-wrapper {
			align-items: flex-start;
			padding: 0 20px;
		}

		.swiper-button-next {
			left: 55%;
			top: auto;
			bottom: 0px;
		}

		.swiper-button-prev {
			left: 35%;
			top: auto;
			bottom: 0px;
		}
	}
}

@media screen and (max-width: 480px) {
	.styled-swiper-gallery {
		.swiper-wrapper {
			padding: 0;
		}
	}
}
