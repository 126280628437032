.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 120px 0;
	width: 100%;
}

.title {
	max-width: 640px;
	margin-bottom: 50px;

	font-family: 'e-Ukraine-Head', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 140%;
	text-align: center;

	color: #ffffff;
}

@media screen and (max-width: 768px) {
	.title {
		padding: 0 30px;

		font-size: 30px;
		line-height: 41px;
	}
}

@media screen and (max-width: 480px) {
	.root {
		height: auto;
	}

	.title {
		padding: 0 20px;
	}
}
