.root {
	display: flex;

	background: #020202;
	background-image: url('./assets/sub-bg.jpg');
	background-position: center;
	background-size: 100% 101%;
	background-repeat: no-repeat;
}

.container {
	padding: 120px 40px;
	margin: 0 auto;
}

@media screen and (max-width: 1280px) {
	.container {
		padding: 80px 40px;
	}
}

@media screen and (max-width: 768px) {
	.root {
		background-image: none;
	}

	.container {
		padding: 60px 20px;
	}

	.title {
		font-size: 30px;
		line-height: 41px;
	}
}
