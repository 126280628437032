.loaderContainer {
	position: relative;
	width: 100%;
	height: 50px;

	padding: 40px 0 90px;

	@media screen and (max-width: 768px) {
		padding-bottom: 70px;
	}
}

.info {
	&Container {
		z-index: 1;

		border: 1px solid #7f8392;
		backdrop-filter: blur(114px);

		border-radius: 50px;

		margin: auto;
		padding: 10vh 10vw 0;
		max-width: 70%;
		overflow: hidden;

		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (max-width: 768px) {
			padding: 15vh 10vw 0;
		}
	}

	&Header {
		margin-bottom: 24px;
		font-family: 'e-Ukraine-Head', sans-serif;
		font-size: 48px;
		line-height: 140%;
		font-style: normal;
		letter-spacing: 0;
		text-align: center;
	}

	&SubTitle {
		font-family: 'e-Ukraine-Head', sans-serif;
		font-size: 20px;
		line-height: 28px;

		text-align: center;
		text-transform: uppercase;

		color: #426cff;
	}

	&Count {
		font-family: 'e-Ukraine-Head', sans-serif;

		margin-top: 1rem;
		font-size: 20px;
		line-height: 28px;

		text-align: center;
		text-transform: uppercase;

		color: #ffffff;
		max-width: 100%;
	}
}

.connectButton {
	&Container {
		margin: 5rem 0 90px;
		display: flex;
		justify-content: center;
		width: 80%;

		@media screen and (max-width: 768px) {
			margin-bottom: 70px;
			width: 100%;
		}

		@media screen and (max-width: 480px) {
			margin-top: 3rem;
			margin-bottom: 20px;
		}
	}

	margin: 0 auto;
	width: 100%;
	font-family: 'e-Ukraine-Head-Medium', sans-serif;
	outline: none;
	font-size: 16px;
	background: #426cff;
	border-radius: 48px;
	color: #fff;
	transition: all 0.3s ease-in-out;
	padding: 1.5rem 3rem;
	border: none;
	cursor: pointer;

	&:hover {
		background-color: #fef151;
		color: #191818;
	}
}

.purchaseCounter {
	&Container {
		margin-top: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&Button {
		background-color: transparent;
		outline: none;
		border: none;
		cursor: pointer;

		img {
			width: 3rem;
			aspect-ratio: 1 / 1;
		}
	}

	&Number {
		font-family: 'e-Ukraine-Head-Medium', sans-serif;
		font-size: 27px;
		margin: 0 3.5rem;
	}
}

@media screen and (max-width: 768px) {
	.info {
		&Header {
			font-size: 30px;
			line-height: 41px;
		}
	}
	.connectButton {
		padding: 1rem 3rem;
	}
}

@media screen and (max-width: 374px) {
	.info {
		&Container {
			padding: 50px 20px 50px 20px;
		}
	}
}

@media screen and (max-width: 480px) and (min-width: 375px) {
	.info {
		&Container {
			padding: 50px 35px;
		}
	}
}

@media screen and (max-width: 480px) {
	.info {
		&Container {
			max-width: 100%;
			margin-right: 20px;
			margin-left: 20px;
		}

		&Count {
			font-size: 18px;
			text-align: left;
		}

		&Header {
			font-size: 22px;
			text-align: left;
		}
	}
	.connectButton {
		margin-bottom: 0;
	}
}
